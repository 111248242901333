import { makeAutoObservable } from 'mobx';
import { MappingPressetApiCreate, MappingPressetWithSymbols } from '../../models/MappingPresset';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { API } from '@api/client/apiEndpoints';
import { TargetTypeEnum } from 'types/targetTypes';
import { Message, MessageType } from '@components/Message/Message';
import get from 'lodash/get';

export class MappingPressetsStore {
    constructor() {
        makeAutoObservable(this);
    }

    isLoading: boolean = false;

    isSaving: boolean = false;

    mappingPressets: MappingPressetWithSymbols[] = [];

    symbolMappingPreset: MappingPressetWithSymbols | null = null;

    setData(value: MappingPressetWithSymbols[]) {
        this.mappingPressets = value;
    }

    async getMappingPressets(type: TargetTypeEnum) {
        this.isLoading = true;
        await API.mappingPressets
            .getMappingPressetsByType(type)
            .then(({ data }) => {
                this.setData(data.sort((a, b) => baseSorter(b.name, a.name)));
            })
            .catch((e) => {
                Message(MessageType.error, e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getAllMappingPressets() {
        this.isLoading = true;
        await API.mappingPressets
            .getAllMappingPressets()
            .then(({ data }) => {
                this.setData(data.sort((a, b) => baseSorter(b.updateDate, a.updateDate)));
            })
            .catch((e) => {
                Message(MessageType.error, e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getMappingPressetWithSymbolsById(id: string): Promise<MappingPressetWithSymbols> {
        this.isLoading = true;
        return new Promise<MappingPressetWithSymbols>((resolve, reject) => {
            API.mappingPressets
                .getMappingPressetById(id)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch(() => {
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async createMappingPresset(data: MappingPressetApiCreate) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            API.mappingPressets
                .createMappingPresset({
                    ...data,
                })
                .then(() => {
                    Message(MessageType.success, 'Symbol Map Template was created');
                    return resolve();
                })
                .catch((e) => {
                    const message = get(e, 'response.data') ?? e?.message ?? e?.error.toString() ?? e?.toString();
                    Message(MessageType.error, `Can't create Symbol Map Template. ${message}`);
                    return reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async deleteMappingPresset(id: string) {
        this.isLoading = true;
        await API.mappingPressets
            .deleteMappingPresset(id)
            .then(() => {
                Message(MessageType.success, 'Symbol Map Template was deleted');
            })
            .catch((e) => {
                debugger;
                const message = get(e, 'response.data') ?? e?.message ?? e?.error.toString() ?? e?.toString();
                Message(MessageType.error, message);
                Promise.reject(e);
            })
            .finally(() => {
                debugger;
                this.isLoading = false;
            });
    }

    async saveMappingPresset(template: MappingPressetWithSymbols) {
        this.isSaving = true;
        await API.mappingPressets
            .updateMappingPresset(template)
            .then(() => {
                Message(MessageType.success, 'Symbol Map Template was saved');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't save Symbol Map Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async copyPresset(id: string) {
        this.isLoading = true;
        await API.mappingPressets
            .copyMappingPresset(id)
            .then(({ data }) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                data && data.message
                    ? Message(MessageType.error, `Can't copy Symbol Map Template. ${data.message}`)
                    : Message(MessageType.success, 'Symbol Map Template was copied');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't copy Symbol Map Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    reset() {
        this.mappingPressets = [];
        this.isLoading = false;
        this.isSaving = false;
        this.symbolMappingPreset = null;
        // this.isImporting = false;
    }
}

const mappingPressetsStore = new MappingPressetsStore();
export { mappingPressetsStore as mappingPressetsStore };
