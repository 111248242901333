/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable } from 'mobx';
import { Message, MessageType } from '@components/Message/Message';
import { API } from '@api/client/apiEndpoints';
import {
    BaseTemplateModel,
    convertBaseTemplates,
    convertToTemplateModel,
    convertToTemplateSwapModel,
    ImportFinaltoType,
    TemplateCreateModel,
    TemplateModel,
    TemplateSwapModel,
} from '@models/Templates';
import { TemplatesControllerSwapsCSVRecord } from '@pages/templatesController/import/importCSV/csvUtils';
import { mergeTemplateSwaps } from '@utils/store';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import {
    convertErrorToValidationSymbolInfoType,
    // convertToValidateSwapType,
    ValidateErrorType,
    // ValidateSwapType,
    ValidationResultType,
} from '@models/ImportTemplates';
import get from 'lodash/get';
import { v4 } from 'uuid';

export class TemplatesControllerStore {
    constructor() {
        makeAutoObservable(this);
    }

    data: BaseTemplateModel[] = [];

    template: TemplateModel | null = null;

    isLoading: boolean = false;

    isImporting: boolean = false;

    isSaving: boolean = false;

    async getTemplatesAll() {
        this.isLoading = true;
        await API.templates
            .getAllTemplates()
            .then(({ data }) => {
                this.data = convertBaseTemplates(data).sort((a, b) =>
                    baseSorter(b.updateDate.unix(), a.updateDate.unix()),
                );
            })
            .catch((e) => {
                Message(MessageType.error, e);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getTemplateById(id: string): Promise<TemplateModel> {
        this.isLoading = true;
        return new Promise<TemplateModel>((resolve, reject) => {
            API.templates
                .getTemplateById(id)
                .then(({ data }) => {
                    return resolve(convertToTemplateModel(data));
                })
                .catch(() => {
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async createTemplate(data: TemplateCreateModel, swaps?: any) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            API.templates
                .create({
                    ...data,
                    swaps,
                })
                .then(() => {
                    Message(MessageType.success, 'Symbols Template was created');
                    return resolve();
                })
                .catch((e) => {
                    const message = get(e, 'response.data.error') ?? e?.message ?? e?.error.toString() ?? e?.toString();
                    Message(MessageType.error, `Can't create Symbols Template. ${message}`);
                    return reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async deleteTemplate(id: string) {
        this.isLoading = true;
        await API.templates
            .delete(id)
            .then(() => {
                Message(MessageType.success, 'Symbols Template was deleted');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't delete Symbols Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async copyTemplate(id: string) {
        this.isLoading = true;
        await API.templates
            .copyTemplate(id)
            .then(({ data }) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                data && data.message
                    ? Message(MessageType.error, `Can't copy Symbols Template. ${data.message}`)
                    : Message(MessageType.success, 'Symbols Template was copied');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't copy Symbols Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async saveTemplate(template: TemplateModel) {
        this.isSaving = true;
        await API.templates
            .update(template)
            .then(() => {
                Message(MessageType.success, 'Symbols Template was saved');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't save Symbols Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    updateSwapsFromCSV({ templateId, records }: { templateId: string; records: TemplatesControllerSwapsCSVRecord[] }) {
        this.isLoading = true;
        return new Promise((resolve, reject) => {
            this.getTemplateById(templateId)
                .then((data) => {
                    const uptateTemplate: TemplateModel = {
                        ...data,
                        swaps: mergeTemplateSwaps({
                            swaps: data.swaps,
                            csvRecords: records,
                            templateType: data.templateType,
                        }),
                    };
                    this.saveTemplate(uptateTemplate);
                    return resolve(data);
                })
                .catch(() => {
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    validateImportFinaltoCSV = (values: ImportFinaltoType) => {
        this.isImporting = true;
        return new Promise<ValidationResultType>((resolve, reject) => {
            API.templates
                .validateImportFinalto(values)
                .then(({ data }) => {
                    if (data && typeof data === 'object') {
                        const swaps = Array.isArray(data.swaps) ? data.swaps : [];
                        // @ts-ignore
                        const swapsRaw = swaps.map(({ id, swapsEnabled, ...item }) => item);
                        const swapsParsed: TemplateSwapModel[] = swaps.map((swap: any) => ({
                            ...convertToTemplateSwapModel(swap),
                            id: v4(),
                        }));
                        const errorsRaw = Array.isArray(data.errors) ? data.errors : [];
                        const errorsParsed: ValidateErrorType[] = errorsRaw.map((error: any) =>
                            convertErrorToValidationSymbolInfoType(error),
                        );
                        return resolve({
                            swapsRaw,
                            swapsParsed,
                            errorsParsed,
                        });
                    }
                })
                .catch((e) => {
                    Message(
                        MessageType.error,
                        `Import failed. ${e?.response?.data?.error ?? e?.message ?? e?.error ?? e?.toString()}`,
                    );
                    return reject();
                })
                .finally(() => {
                    this.isImporting = false;
                });
        });
    };

    resetTemplate() {
        this.template = null;
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.isSaving = false;
        this.template = null;
        this.isImporting = false;
    }
}

const templatesControllerStore = new TemplatesControllerStore();
export { templatesControllerStore };
