import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BackIcon from '@assets/icons/back.svg';
import styles from './editTemplate.module.scss';
import { useParams } from 'react-router-dom';
import { IFormContext, IFormData } from 'types/commonTypes';
import { Button, Tooltip } from 'antd';
import { AppRoutes } from '@constants';
import { observer } from 'mobx-react';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { runInAction } from 'mobx';
import { MappingPressetSymbol, MappingPressetWithSymbols } from '@models/MappingPresset';
import { MappingPresetSymbolsTable } from './MappingPressetSymbolsTable/MappingPressetSymbolsTable';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';

export const FormContext = createContext<IFormContext<MappingPressetWithSymbols>>(null!);

export const MappingPressetSymbolsDetails = observer(() => {
    const [formData, setFormData] = useState<IFormData<MappingPressetWithSymbols> | undefined>(undefined);
    const [localData, setLocalData] = useState<MappingPressetSymbol[]>([]);
    const [idsOfEditedRow, setIdsOfEditedRow] = useState<string[]>([]);
    const { templateId: symbolMappingPresetId } = useParams();

    useEffect(() => {
        if (symbolMappingPresetId) {
            runInAction(() => {
                mappingPressetsStore.getAllMappingPressets();
                mappingPressetsStore.getMappingPressetWithSymbolsById(symbolMappingPresetId!).then((preset) => {
                    mappingPressetsStore.symbolMappingPreset = preset;
                    setLocalData(preset.mappingPressetSymbols);
                });
            });
        }
    }, [symbolMappingPresetId]);

    useEffect(() => {
        return () => {
            mappingPressetsStore.reset();
        };
    }, []);

    const navigate = useNavigate();

    const clickBackHandler = () => {
        navigate(AppRoutes.mappingPresets);
        templatesControllerStore.resetTemplate();
    };

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <Tooltip title="Back to Symbol Map Templates">
                        <Button type="text" className={styles.headerButtonBack} onClick={clickBackHandler}>
                            <img alt="logo" className="logo_image" src={BackIcon} />
                        </Button>
                    </Tooltip>
                    <MappingPresetSymbolsTable
                        mappingSymbols={localData}
                        setLocalData={setLocalData}
                        idsOfEditedRow={idsOfEditedRow}
                        setIdsOfEditedRow={setIdsOfEditedRow}
                    />
                </div>
            </div>
        </FormContext.Provider>
    );
});
