import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { TemplateControllerDetails } from '@pages/templateControllerDetails/templateControllerDetails';
import { selectServerStore } from '@store/selectServer';
import { ServersSettings } from '@pages/serversSettings/ServersSettings';
import { History } from '@pages/history/History';
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication, useIsAuthenticated } from '@azure/msal-react';
import { SelectServerForm } from '@pages/selectServer/SelectServer';
import { SwapTemplates } from '@pages/swapTemplates/SwapTemplates';
import styles from './style.module.scss';
import { useReaction } from '@utils/hooks';
import { Main } from '@pages/main/Main';
import { TemplatesController } from '@pages/templatesController/TemplatesController';
import { AppRoutes } from '@constants';
import { Settings } from '@pages/Settings/Settings';
import { DividentTemplates } from '@pages/dividentTemplates/DividentTemplates';
import { HistoryDetailes } from '@pages/history/historyDetailes/HistoryDetailes';
import { DividentUpdater } from '@pages/dividentUpdater/DividentUpdater';
import { Presets } from '@pages/presets/Presets';
import { UpdaterTool } from '@pages/updaterTool/UpdaterTool';
import { MappingPressetsController } from '@pages/MappingPressets/MappingPressetsController';
import { MappingPressetSymbolsDetails } from '@pages/MappingPressets/MappinPressetSymbols/MappingPressetSymbolsDetails';

interface Props {
    children?: JSX.Element;
}

const ProtectedPage: React.FC<Props> = ({ children }) => {
    const [version] = useReaction<string>(() => selectServerStore.version);
    const isAuthenticated = useIsAuthenticated();
    const { error } = useMsalAuthentication(InteractionType.Redirect);

    if (error) {
        return (
            <div className={styles.nonAuthError}>
                There has been an error: Code: ${error.errorCode}, Message: ${error.errorMessage}, Submessage: $
                {error.subError}
            </div>
        );
    }

    if (!isAuthenticated) {
        return (
            <div className={styles.nonAuth}>
                <Title level={4}>NOT Authenticated</Title>
            </div>
        );
    }

    if (!version) {
        return <Navigate to={AppRoutes.selectServer} replace />;
    }

    return <Main>{children}</Main>;
};

export const Content: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route
                path={AppRoutes.main}
                element={
                    <ProtectedPage>
                        <TemplatesController />
                    </ProtectedPage>
                }
            />
            <Route
                path={`${AppRoutes.templatesController}/:templateId`}
                element={
                    <ProtectedPage>
                        <TemplateControllerDetails />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.updaterTool}
                element={
                    <ProtectedPage>
                        <SwapTemplates />
                    </ProtectedPage>
                }
            />
            <Route
                path={`${AppRoutes.updaterTool}/:templateId`}
                element={
                    <ProtectedPage>
                        <UpdaterTool />
                    </ProtectedPage>
                }
            />
            <Route
                path={`${AppRoutes.dividendsUpdaterTool}/:templateId`}
                element={
                    <ProtectedPage>
                        <DividentUpdater />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.dividendsUpdaterTool}
                element={
                    <ProtectedPage>
                        <DividentTemplates />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.settings}
                element={
                    <ProtectedPage>
                        <Settings />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.serversSettings}
                element={
                    <ProtectedPage>
                        <ServersSettings />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.mappingPresets}
                element={
                    <ProtectedPage>
                        <MappingPressetsController />
                    </ProtectedPage>
                }
            />
            <Route
                path={`${AppRoutes.mappingPresetSymbols}/:templateId`}
                element={
                    <ProtectedPage>
                        <MappingPressetSymbolsDetails />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.history}
                element={
                    <ProtectedPage>
                        <History />
                    </ProtectedPage>
                }
            />
            <Route
                path={AppRoutes.presets}
                element={
                    <ProtectedPage>
                        <Presets />
                    </ProtectedPage>
                }
            />
            <Route
                path={`${AppRoutes.history}/:historyId/:templateType`}
                element={
                    <ProtectedPage>
                        <HistoryDetailes />
                    </ProtectedPage>
                }
            />
            <Route path={AppRoutes.selectServer} element={<SelectServerForm />} />
            <Route
                path="*"
                element={
                    <ProtectedPage>
                        <TemplatesController />
                    </ProtectedPage>
                }
            />
        </Routes>
    );
};
