import { TableExt } from '@components/TableExt/TableExt';
import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import Copy from '@assets/icons/copy.svg';
import { useNavigate } from 'react-router';
import { useReaction } from '@utils/hooks';
import { Message, MessageType } from '@components/Message/Message';
import styles from './styles.module.scss';
import { AppRoutes } from '@constants';
import { columns } from './columns';
import { observer } from 'mobx-react';
import { CreateButton } from '@components/Form/Buttons/CreateButton';
import { MappingPresset, MappingPressetApiCreate } from '@models/MappingPresset';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';
import { CreateMappingPressetModal } from '../createMappingPressetModal/createMappingPressetModal';

export const ROW_KEY = 'id';

export const SymbolsMappingTemplateTable = observer(() => {
    const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
    const [templates] = useReaction<MappingPresset[]>(() => mappingPressetsStore.mappingPressets);
    const [isLoading] = useReaction<boolean>(() => mappingPressetsStore.isLoading);
    const [isSaving] = useReaction<boolean>(() => mappingPressetsStore.isSaving);

    const navigate = useNavigate();

    const handleOpenCreateServerForm = () => {
        setIsOpenCreateModal(true);
    };

    const renderToolbar = useCallback(
        () => [
            <div className={styles.buttonsGroup}>
                <CreateButton
                    onClick={handleOpenCreateServerForm}
                    label="Create Symbol Map Template"
                    tooltip="Create a new Symbol Map Template"
                />
            </div>,
        ],
        [],
    );

    const onSubmitModalCreate = (value: MappingPressetApiCreate) => {
        mappingPressetsStore.createMappingPresset(value).finally(() => {
            mappingPressetsStore.getAllMappingPressets();
        });
        setIsOpenCreateModal(false);
    };

    const handleCancelCreateClick = () => {
        setIsOpenCreateModal(false);
    };

    const handleCopyTemplateClick = (id: string) => {
        const targetTemplate = templates.find((templ) => templ.id === id);
        if (!targetTemplate) {
            Message(MessageType.error, `Can't find template to copy.`);
            return;
        }
        if (targetTemplate.name.length < 45) {
            mappingPressetsStore.copyPresset(id).finally(() => {
                mappingPressetsStore.getAllMappingPressets();
            });
        } else {
            Message(
                MessageType.error,
                `The file name would be too long for the copy. You can shorten the template name and try again.`,
            );
        }
    };

    const deleteCondition = (id: string) => {
        Modal.confirm({
            title: `Symbol Map Template will be deleted. This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                mappingPressetsStore.deleteMappingPresset(id).finally(() => {
                    mappingPressetsStore.getAllMappingPressets();
                });
            },
        });
    };

    const handleEditClick = (id: string) => {
        const targetTemplate = templates.find((item) => item.id === id);
        if (targetTemplate) {
            navigate(`${AppRoutes.mappingPresetSymbols}/${id}`);
        }
    };

    return (
        <>
            <TableExt<MappingPresset>
                rowKey={ROW_KEY}
                title="Symbol Map Templates"
                columns={columns}
                dataSource={templates}
                headerToolbar={renderToolbar}
                loading={isLoading || isSaving}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: handleEditClick,
                    custom: [
                        {
                            icon: () => <img alt="logo" src={Copy} />,
                            onClick: handleCopyTemplateClick,
                            text: 'Copy',
                        },
                    ],
                    onDelete: deleteCondition,
                }}
            />
            {isOpenCreateModal && (
                <CreateMappingPressetModal onSubmit={onSubmitModalCreate} onCancel={handleCancelCreateClick} />
            )}
        </>
    );
});
