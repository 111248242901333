/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Input, Select } from 'antd';
import {
    Control,
    Controller,
    FieldErrors,
    FieldNamesMarkedBoolean,
    FieldValues,
    UseFormTrigger,
} from 'react-hook-form';
import cn from 'classnames';
import { RightPanelCard } from '@components/RightPanelForm/Groups';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { OperationType, TargetType, targetTypesArr } from 'types/commonTypes';
import { ServerModel } from '@models/Server';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { MappingPresset } from '../../../models/MappingPresset';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export const DEFAULT_NAME_PRESET = 'New Preset';
type Props = {
    control: Control<ServerModel>;
    errors: FieldErrors<FieldValues>;
    dirtyFields: FieldNamesMarkedBoolean<FieldValues>;
    trigger: UseFormTrigger<ServerModel>;
    serverType: string | null;
    formType?: OperationType;
    mappingPressets: MappingPresset[];
};

export const General: FC<Props> = ({
    control,
    trigger,
    serverType,
    formType,
    errors,
    dirtyFields,
    mappingPressets: mappingPressets,
}) => {
    return (
        <RightPanelCard>
            <FieldContainer>
                <FieldLabel title="Server Type" required />
                <Controller
                    control={control}
                    name="type"
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                disabled={formType !== OperationType.update}
                                onChange={(val) => {
                                    field.onChange(val);
                                    trigger('taName');
                                }}
                                className={cn({
                                    changed: dirtyFields.type,
                                    invalid: !!errors.type?.message,
                                })}
                                value={field.value ?? undefined}
                                options={targetTypesArr.map((item) => ({ value: item.type, label: item.title }))}
                            />
                        );
                    }}
                />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Name" required />
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                value={field.value ?? undefined}
                                className={cn({
                                    changed: dirtyFields.name,
                                    invalid: !!errors.name?.message,
                                })}
                                size="middle"
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.name?.message} />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Taker Name" required={serverType !== TargetType.takerFix} />
                <Controller
                    control={control}
                    name="taName"
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                value={field.value ?? undefined}
                                className={cn({
                                    changed: dirtyFields.taName,
                                    invalid: !!errors.taName?.message,
                                })}
                                onChange={(e) => {
                                    field.onChange(e);
                                }}
                                size="middle"
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.taName?.message} />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="URL" required />
                <Controller
                    control={control}
                    name="address"
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                value={field.value ?? undefined}
                                className={cn({
                                    changed: dirtyFields.address,
                                    invalid: !!errors.address?.message,
                                })}
                                size="middle"
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.address?.message} />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Username" required />
                <Controller
                    control={control}
                    name="username"
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                value={field.value ?? undefined}
                                className={cn({
                                    changed: dirtyFields.username,
                                    invalid: !!errors.username?.message,
                                })}
                                size="middle"
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.username?.message} />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Password" required />
                <Controller
                    control={control}
                    name="password"
                    render={({ field }) => {
                        return (
                            <Input.Password
                                {...field}
                                visibilityToggle={formType === OperationType.create}
                                value={field.value ?? undefined}
                                className={cn({
                                    changed: dirtyFields.password,
                                    invalid: !!errors.password?.message,
                                })}
                                size="middle"
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.password?.message} />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Symbol Map Template" />
                <Controller
                    control={control}
                    name="mappingPressetId"
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                // visibilityToggle={formType === OperationType.create}
                                onChange={(val) => {
                                    field.onChange(val ?? null);
                                }}
                                value={field.value ?? undefined}
                                optionFilterProp="label"
                                options={mappingPressets
                                    .map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                    }))
                                    .sort((a, b) => baseSorter(a.label, b.label))}
                                className={cn({
                                    changed: dirtyFields.mappingPressetId,
                                    invalid: !!errors.mappingPressetId?.message,
                                })}
                                size="middle"
                                allowClear
                                showSearch
                            />
                        );
                    }}
                />
                <ValidationMessage message={errors.mappingPressetId?.message} />
            </FieldContainer>
        </RightPanelCard>
    );
};
