/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomColumnType } from '@components/TableExt/types';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { MappingPressetSymbol } from '@models/MappingPresset';

const tableColKeys = {
    symbolFrom: 'symbolFrom',
    symbolTo: 'symbolTo',
};

export const colsToExport = ({}: {}): {
    key: string;
    title: string;
    formatter?: (val: any) => string | any;
}[] => {
    let cols = [
        { key: tableColKeys.symbolFrom, title: 'Symbol From' },
        { key: tableColKeys.symbolTo, title: 'Symbol To' },
    ];

    return cols;
};

export const columns: ({}: {}) => CustomColumnType<MappingPressetSymbol>[] = ({}) => {
    let cols: CustomColumnType<MappingPressetSymbol>[] = [
        {
            title: 'Symbol From',
            dataIndex: tableColKeys.symbolFrom,
            align: 'left',
            sorter: (a, b) => baseSorter(a.symbolFrom, b.symbolFrom),
            filterOptions: { type: 'Search' },
        },
        {
            title: 'Symbol To',
            dataIndex: tableColKeys.symbolTo,
            align: 'left',
            filterOptions: { type: 'Search' },
        },
    ];

    return cols;
};
