import React, { useContext, Context, useMemo, useEffect, useState } from 'react';
import { RightPanel } from '@components/RightPanelForm';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ServerModel } from '@models/Server';
import { serversStore } from '@store/servers/serversStore';
import { serverSchema } from './schema';
import { FormContext } from '../ServersSettings';
import { useReaction } from '@utils/hooks';
import { General } from './General';
import { IFormContext, OperationType } from 'types/commonTypes';
import { DEL_SERVER_CONFIRM_TEXT } from '@constants';
import { Message, MessageType } from '@components/Message/Message';
import { mappingPressetsStore } from '../../../store/mappingPressets/mappingPressetsStore';
import { MappingPresset } from '../../../models/MappingPresset';

export const ServerPanelRight: React.FC = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { formData, setFormData } = useContext<IFormContext<ServerModel>>(
        FormContext as Context<IFormContext<ServerModel>>,
    );

    const [servers] = useReaction<ServerModel[]>(() => serversStore.data);
    const defaultValues = useMemo(() => formData?.currentRow, [servers, formData?.currentRow, formData?.type]);

    const {
        handleSubmit,
        reset,
        control,
        trigger,
        watch,
        formState: { isDirty, isValid, errors, dirtyFields },
    } = useForm<ServerModel>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(serverSchema),
    });

    const serverType = watch('type');

    useEffect(() => {
        reset(defaultValues);
        trigger();
        mappingPressetsStore.getMappingPressets(serverType);
    }, [defaultValues, serverType]);

    const [mappingPressets] = useReaction<MappingPresset[]>(() => mappingPressetsStore.mappingPressets);

    const handleDelete = () => {
        if (formData?.currentRow?.id && formData?.type === OperationType.update) {
            serversStore.deleteServer(formData?.currentRow?.id).finally(() => {
                setFormData(undefined);
            });
        }
    };

    const handleReset = () => {
        reset(defaultValues);
    };

    const handleClose = () => {
        reset(defaultValues);
        setFormData(undefined);
        serversStore.getServers();
    };

    const onSubmit: SubmitHandler<ServerModel> = (data) => {
        setIsSubmitting(true);
        new Promise<void>((resolve) => {
            (formData?.type === OperationType.update
                ? serversStore.update(data as ServerModel)
                : serversStore.create(data as ServerModel)
            )
                .then(() => {
                    if (formData?.type === OperationType.create) {
                        Message(MessageType.success, 'Server was created');
                    } else {
                        Message(MessageType.success, 'Changes were saved');
                    }
                    resolve();
                    handleClose();
                })
                .catch((e) => {
                    const errorTexts = e?.response?.data;
                    const errorText =
                        Array.isArray(errorTexts) && errorTexts.length > 0
                            ? errorTexts.map((et) => ((et ?? '').endsWith('.') ? et : `${et}.`)).join(' ')
                            : e?.message;
                    if (formData?.type === OperationType.create) {
                        Message(MessageType.error, `Can't create Server. ${errorText}`);
                    } else {
                        Message(MessageType.error, `Can't update Server. ${errorText}`);
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        });
    };

    return (
        <RightPanel<ServerModel>
            entityTitle="MT_Web_API"
            name="MT Web API"
            deleteConfirmationMessage={DEL_SERVER_CONFIRM_TEXT}
            type={formData!.type}
            isOpen={!!formData}
            isTouched={isDirty}
            isValid={isValid}
            onClose={handleClose}
            onDelete={formData!.type === OperationType.update ? handleDelete : undefined}
            onReset={handleReset}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            submitBtnTitle={formData!.type === OperationType.create ? 'Create' : 'Save'}
        >
            <General
                control={control}
                errors={errors}
                trigger={trigger}
                dirtyFields={dirtyFields}
                serverType={serverType}
                formType={formData!.type}
                mappingPressets={mappingPressets}
            />
        </RightPanel>
    );
};
