/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles.module.scss';
import { useReaction } from '@utils/hooks';
import { schemaCreateTemplate } from '@pages/templatesController/createTemplateControllerModal/schema';
import { Button, Input } from 'antd';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { TargetTypesList } from 'types/targetTypes';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { MappingPressetSymbol, MappingPressetWithSymbols } from '@models/MappingPresset';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';

export const EditForm: React.FC<{
    localSymbols: MappingPressetSymbol[];
    setIdsOfEditedRow: Dispatch<SetStateAction<string[]>>;
    actions: () => JSX.Element[];
    setLocalData: (val: MappingPressetSymbol[]) => void;
}> = observer(({ localSymbols: localSymbols, setIdsOfEditedRow, actions, setLocalData }) => {
    const [symbolMappingPreset] = useReaction<MappingPressetWithSymbols | null>(
        () => mappingPressetsStore.symbolMappingPreset,
    );
    const [isSaving] = useReaction<boolean>(() => mappingPressetsStore.isSaving);
    const [isLoading] = useReaction<boolean>(() => mappingPressetsStore.isLoading);
    const isTouchedCustom = !(
        JSON.stringify(symbolMappingPreset?.mappingPressetSymbols) === JSON.stringify(localSymbols)
    );

    const {
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty, dirtyFields },
        control,
    } = useForm<MappingPressetWithSymbols>({
        defaultValues: {},
        mode: 'all',
        resolver: yupResolver(schemaCreateTemplate),
    });

    useEffect(() => {
        if (symbolMappingPreset) {
            reset(symbolMappingPreset);
        }
    }, [symbolMappingPreset]);

    const onSave: SubmitHandler<MappingPressetWithSymbols> = (data) => {
        console.log(data);
        mappingPressetsStore
            .saveMappingPresset({
                ...data,
                mappingPressetSymbols: localSymbols,
            })
            .finally(() => {
                mappingPressetsStore.getMappingPressetWithSymbolsById(data.id).then((templateData) => {
                    mappingPressetsStore.symbolMappingPreset = templateData;
                    setLocalData(templateData.mappingPressetSymbols);
                });
            });
        setTimeout(() => {
            setIdsOfEditedRow([]);
        }, 500); // TODO
    };

    return (
        <div className={styles.container}>
            <form id="editPanelTop" className={styles.form}>
                <div className={styles.panelTopFields}>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Name:</div>
                        <FieldContainer className={styles.fieldContainer}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field, field: { onChange } }) => (
                                    <Input
                                        {...field}
                                        disabled={isLoading}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                        className={cn({
                                            changed: dirtyFields.name,
                                            invalid: !!errors.name?.message,
                                        })}
                                        value={field.value ?? undefined}
                                        size="small"
                                    />
                                )}
                            />
                            <ValidationMessage message={errors.name?.message} />
                        </FieldContainer>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Server Type:</div>
                        <FieldContainer className={styles.fieldContainer}>
                            <Controller
                                control={control}
                                name="type"
                                render={({ field }) => {
                                    return (
                                        <div className={styles.textValue}>
                                            {TargetTypesList.find((item) => item.key === field.value)?.title ?? ''}
                                        </div>
                                    );
                                }}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className={styles.btnBlock}>
                    {actions()}
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSubmit(onSave)}
                        disabled={!isValid || (!isDirty && !isTouchedCustom)}
                        className={styles.footerButton}
                        loading={isSaving}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
});
