/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { TableExt } from '@components/TableExt/TableExt';
import { Button, Modal } from 'antd';
import styles from '../editTemplate.module.scss';
import { useReaction } from '@utils/hooks';
import { /*colsToExport,*/ columns } from './columns';
import { MappingSymbolModal } from '../MappingSymbolModal/MappingSymbolModal';
import { OperationTypeBtnTitle } from 'types/commonTypes';
import { observer } from 'mobx-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { EditForm } from '../editFrom/EditForm';
import { useNavigate } from 'react-router';
import { AppRoutes } from '@constants';
import { Message, MessageType } from '@components/Message/Message';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { MappingPressetSymbol, MappingPressetWithSymbols } from '@models/MappingPresset';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';

type Props = {
    mappingSymbols: MappingPressetSymbol[];
    setIdsOfEditedRow: Dispatch<SetStateAction<string[]>>;
    setLocalData: (val: MappingPressetSymbol[]) => void;
    idsOfEditedRow: string[];
};

export const MappingPresetSymbolsTable: React.FC<Props> = observer(
    ({ mappingSymbols, setLocalData, setIdsOfEditedRow, idsOfEditedRow }) => {
        const [isLoading] = useReaction<boolean>(() => mappingPressetsStore.isLoading);
        const [isSaving] = useReaction<boolean>(() => mappingPressetsStore.isSaving);
        const [template] = useReaction<MappingPressetWithSymbols | null>(
            () => mappingPressetsStore.symbolMappingPreset,
        );
        const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
        const [idSwapToEdit, setIdSwapToEdit] = useState<string | null>(null);
        const navigate = useNavigate();

        const handleOpenCreateSwapModal = () => {
            setIdSwapToEdit(null);
            setIsOpenModal(true);
        };

        const deleteHandler = () => {
            Modal.confirm({
                title: 'Symbol Map Template will be deleted. This action cannot be undone.',
                icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                onOk: () => {
                    if (template && template.id) {
                        mappingPressetsStore.deleteMappingPresset(template.id).then(() => {
                            navigate(AppRoutes.mappingPresets);
                        });
                    } else {
                        Message(MessageType.error, 'Symbol Map Template id is not set');
                    }
                },
            });
        };

        const renderToolbar = useCallback(
            () => [
                <div className={cn(styles.buttonsGroup, styles.buttonsGroupTableDetails)}>
                    <Button
                        type="default"
                        danger
                        onClick={deleteHandler}
                        className={styles.deleteTemplateBtn}
                        disabled={!template}
                    >
                        Delete Symbol Map Template
                    </Button>
                    {template && (
                        <div className={styles.importCSVBtnWrapper}>
                            <ReactTooltip
                                id="tooltip-csvImport"
                                render={() => <span className={styles.csvToolTip}>Import</span>}
                                className="ant-tooltip-inner"
                                place="left"
                            />
                        </div>
                    )}

                    <Button
                        style={{ background: '#FCB70B', border: 'none' }}
                        key="update"
                        type="primary"
                        onClick={handleOpenCreateSwapModal}
                    >
                        Create Symbol
                    </Button>
                </div>,
            ],
            [mappingSymbols],
        );
        const onSubmitModal = ({ value, isNewRecord }: { value: MappingPressetSymbol; isNewRecord: boolean }) => {
            if (isNewRecord) {
                setLocalData([...mappingSymbols, value]);
            } else {
                setIdsOfEditedRow((prev) => prev.concat(value.id));
                setLocalData([...mappingSymbols.map((swap) => (swap.id === value.id ? value : swap))]);
            }
            setIsOpenModal(false);
            setIdSwapToEdit(null);
        };

        const handleDeleteClick = (id: string) => {
            Modal.confirm({
                title: `The symbol will be deleted. This action cannot be undone.`,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                onOk: () => {
                    const updatedSwap = mappingSymbols.filter((swap: MappingPressetSymbol) => {
                        return swap.id !== id;
                    });
                    setLocalData(updatedSwap);
                },
            });
        };

        const handleCancelModalClick = () => {
            setIsOpenModal(false);
            setIdSwapToEdit(null);
        };

        const handleEditClick = (id: string) => {
            const targetSwap = mappingSymbols.find((item) => item.id === id);
            if (targetSwap) {
                setIsOpenModal(true);
                setIdSwapToEdit(id);
            }
        };

        const rowClassNameHandler = (a: MappingPressetSymbol) => {
            if (idsOfEditedRow.includes(a.id)) return 'editedRowTable';
            return '';
        };

        return (
            <>
                <TableExt<MappingPressetSymbol>
                    rowKey="id"
                    titleJSX={() => (
                        <EditForm
                            localSymbols={mappingSymbols}
                            setIdsOfEditedRow={setIdsOfEditedRow}
                            actions={renderToolbar}
                            setLocalData={setLocalData}
                        />
                    )}
                    columns={columns({ type: template?.type ?? null })}
                    loading={isLoading || isSaving}
                    dataSource={mappingSymbols}
                    focusedRowEntityId={idSwapToEdit}
                    rowClassNameHandler={rowClassNameHandler}
                    actionColumn={{
                        title: 'Actions',
                        dataIndex: 'id',
                        fixed: false,
                        onEdit: (id) => handleEditClick(id),
                        onDelete: (id) => handleDeleteClick(id),
                    }}
                />
                {isOpenModal && template && (
                    <MappingSymbolModal
                        onSubmit={onSubmitModal}
                        title={idSwapToEdit === null ? 'Create Symbol:' : 'Edit Symbol:'}
                        submitBtnTitle={
                            idSwapToEdit === null ? OperationTypeBtnTitle.create : OperationTypeBtnTitle.update
                        }
                        onCancel={handleCancelModalClick}
                        symbols={mappingSymbols}
                        idSymbolToEdit={idSwapToEdit}
                        mappingPreset={template}
                    />
                )}
            </>
        );
    },
);
