/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Input } from 'antd';
import { OperationType, OperationTypeBtnTitle } from 'types/commonTypes';
import cn from 'classnames';
import styles from '../editTemplate.module.scss';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { mappingPresetSymbolSchema } from './schema';
import { MappingPressetSymbol, MappingPressetWithSymbols } from '@models/MappingPresset';
import { v4 } from 'uuid';

const FORM_ID = 'EditMappingPresetSymbols';

const getTargetSymbol = (symbols: MappingPressetSymbol[], symbolId: string): MappingPressetSymbol | null => {
    return symbols.find((symbol) => symbol.id === symbolId) ?? null;
};
export const MappingSymbolModal = (props: {
    onSubmit: ({ value, isNewRecord }: { value: MappingPressetSymbol; isNewRecord: boolean }) => void;
    current?: MappingPressetSymbol;
    title: string;
    submitBtnTitle?: string;
    onCancel: () => void;
    symbols: MappingPressetSymbol[];
    idSymbolToEdit: string | null;
    mappingPreset: MappingPressetWithSymbols;
}) => {
    const {
        onSubmit,
        onCancel,
        title,
        submitBtnTitle,
        symbols: symbols,
        idSymbolToEdit: idSymbolToEdit,
        mappingPreset: template,
    } = props;

    const defaultNewSymbol = mappingPresetSymbolSchema(symbols).cast({ id: v4() });

    const defaultValues =
        idSymbolToEdit === null ? defaultNewSymbol : getTargetSymbol(symbols, idSymbolToEdit) ?? defaultNewSymbol;

    const {
        handleSubmit,
        reset,
        formState: { isDirty, isValid, errors, dirtyFields },
        control,
    } = useForm<MappingPressetSymbol>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(mappingPresetSymbolSchema(symbols)),
    });

    const handleReset = () => {
        reset(defaultValues);
    };
    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    if (!template) return null;

    const submitHandler = (value: MappingPressetSymbol) => {
        onSubmit({ value, isNewRecord: idSymbolToEdit === null });
    };

    return (
        <Modal className={styles.modal} open onCancel={cancelHandler} footer={null}>
            <Title level={3}>{title}</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <FieldLabel title="Symbol From" required />
                    <Controller
                        control={control}
                        name="symbolFrom"
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    className={
                                        submitBtnTitle === OperationTypeBtnTitle.update
                                            ? cn({
                                                  changed: dirtyFields.symbolFrom,
                                                  invalid: !!errors.symbolFrom?.message,
                                              })
                                            : ''
                                    }
                                    value={field.value ?? undefined}
                                />
                            );
                        }}
                    />
                    <ValidationMessage message={errors.symbolFrom?.message} />
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel title="Symbol To" required />
                    <Controller
                        control={control}
                        name="symbolTo"
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    className={
                                        submitBtnTitle === OperationTypeBtnTitle.update
                                            ? cn({
                                                  changed: dirtyFields.symbolTo,
                                                  invalid: !!errors.symbolTo?.message,
                                              })
                                            : ''
                                    }
                                    value={field.value ?? undefined}
                                />
                            );
                        }}
                    />
                    <ValidationMessage message={errors.symbolTo?.message} />
                </FieldContainer>
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(submitHandler)}
                    submitBtnTitle={submitBtnTitle}
                />
            </form>
        </Modal>
    );
};
