import { yupCommonString } from '@utils/yupValidations';
import * as yup from 'yup';
import { TargetTypeEnum } from 'types/targetTypes';
import { MappingPressetApiCreate } from '@models/MappingPresset';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';

export const schemaCreateSymbolMappingTemplate = yup.object().shape({
    name: yupCommonString
        .max(50, 'Must be less than 50 characters')
        .default('')
        .test('uniqueName', 'This name already exists', (value, { parent: {} }) => {
            const templates: MappingPressetApiCreate[] = mappingPressetsStore.mappingPressets;
            return !templates.find((m) => (m.name ?? '').toLowerCase() === value.toLowerCase());
        })
        .required('Required field'),
    type: yup.mixed().oneOf(Object.values(TargetTypeEnum)).default(TargetTypeEnum.mt4).required('Required field'),
});
