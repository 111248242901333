/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Input, Radio } from 'antd';
import { RowContainer } from '@components/Form/RowContainer/RowContainer';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import styles from './styles.module.scss';
import { OperationType } from 'types/commonTypes';
import { schemaCreateSymbolMappingTemplate } from './schema';
import { TargetTypesList } from 'types/targetTypes';
import { MappingPressetApiCreate } from '@models/MappingPresset';

const FORM_ID = 'CreateMappingPressetModal';

const defaultTemplate = schemaCreateSymbolMappingTemplate.cast({});

type Props = {
    onSubmit: (value: MappingPressetApiCreate) => void;
    onCancel: () => void;
};

export const CreateMappingPressetModal = ({ onSubmit, onCancel }: Props) => {
    const {
        handleSubmit,
        reset,
        // watch,
        formState: { isDirty, isValid, isSubmitSuccessful, errors },
        control,
    } = useForm<MappingPressetApiCreate>({
        defaultValues: defaultTemplate,
        mode: 'all',
        resolver: yupResolver(schemaCreateSymbolMappingTemplate),
    });

    const handleReset = () => {
        reset(defaultTemplate);
    };

    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    useEffect(() => {
        handleReset();
    }, [isSubmitSuccessful]);

    return (
        <Modal open onCancel={cancelHandler} footer={null}>
            <Title level={3}>Create Symbol Map Template</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <FieldLabel title="Name" required />
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => {
                            return <Input {...field} value={field.value ?? undefined} />;
                        }}
                    />
                    <ValidationMessage message={errors.name?.message} />
                </FieldContainer>
                <RowContainer>
                    <FieldContainer className={styles.typesTemplates}>
                        <FieldLabel title="Server Type" required />
                        <Controller
                            control={control}
                            name="type"
                            render={({ field: { value }, field }) => (
                                <Radio.Group {...field} value={value} className={styles.radioGroup}>
                                    {TargetTypesList.map((type) => (
                                        <Radio key={type.id} value={type.key}>
                                            {type.title}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            )}
                        />
                    </FieldContainer>
                </RowContainer>
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(onSubmit)}
                    submitBtnTitle="Create"
                />
            </form>
        </Modal>
    );
};
