import { MappingPressetSymbol as MappingPresetSymbol } from '@models/MappingPresset';
import { yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import * as yup from 'yup';
import { v4 } from 'uuid';

export const mappingPresetSymbolSchema = (symbols: MappingPresetSymbol[]) =>
    yup.object().shape({
        id: yup.string().required().default(v4()),
        symbolFrom: yupEntityNameValidationWithSpaces
            .max(50, 'Must be less than 50 characters')
            .required('Required field')
            .default(''),
        symbolTo: yupEntityNameValidationWithSpaces
            .max(50, 'Must be less than 50 characters')
            .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
                return !symbols.find((m) => (m.symbolTo ?? '') === value! && m.id !== id);
            })
            .required('Required field')
            .default(''),
    });
