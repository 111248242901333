import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { CustomColumnType } from '@components/TableExt/types';
import { MappingPresset } from '@models/MappingPresset';
import { renderTargetType, TargetTypesList } from 'types/targetTypes';

const tableColKeys = {
    name: 'name',
    type: 'type',
};

export const columns: CustomColumnType<MappingPresset>[] = [
    {
        title: 'Name',
        dataIndex: tableColKeys.name,
        align: 'left',
        filterOptions: { type: 'Search' },
        sorter: (a, b) => {
            return baseSorter(a.name, b.name);
        },
    },
    {
        title: 'Server Type',
        dataIndex: tableColKeys.type,
        render: renderTargetType,
        // sorter: (a, b) => baseSorter(renderTargetType(a.type), renderTargetType(b.type)),
        align: 'left',
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTypesList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
];
