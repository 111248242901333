import React, { useEffect } from 'react';
import styles from './MappingPressetsController.module.scss';
import { SymbolsMappingTemplateTable } from './MappingPressetsTable/MappingPressetsTable';
import { mappingPressetsStore } from '@store/mappingPressets/mappingPressetsStore';

export const MappingPressetsController = () => {
    useEffect(() => {
        mappingPressetsStore.getAllMappingPressets();
        return () => {
            mappingPressetsStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <SymbolsMappingTemplateTable />
            </div>
        </div>
    );
};
